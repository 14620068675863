import React, { useEffect, useState } from 'react'
import { useCreateOrderMutation, useVerifyOrderMutation } from './apiSlice';
import { LPLogoWName} from '@assets/index';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@Constants/routes';
import { displayToast } from '@Utils/toast';
import { RootState, useAppSelector } from '@Store/store';
import { CheckIcon } from '@heroicons/react/24/outline';
import styles from './styles';
var CryptoJS = require("crypto-js");

declare global {
  interface Window {
    Razorpay: any; // Replace `any` with the proper type if available
  }
}

interface PricingPlan {
  monthly: {
    amount: number;
    currency: string;
  };
  annual: {
    amount: number;
    currency: string;
  };
}
type PricingData = Record<string, PricingPlan>;

const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};


const PaymentOptions = () => {

  const navigate = useNavigate();
  const { user } = useAppSelector((state: RootState) => state.profile);
  const [selectedPlan, setSelectedPlan] = useState(2);
  const [Country, setCountry] = useState<string>("Other");

  const [createOrder, { data: createOrderData, error: createOrderErr, isLoading: createOrderLoading }] = useCreateOrderMutation();
  const [verifyOrder, { data: verifyOrderData, error: verifyOrderErr, isLoading: verifyOrderLoading }] = useVerifyOrderMutation();

  const Pricing: PricingData = {
    'Other': {
      monthly: {
        amount: 9.99,
        currency: '$'
      },
      annual: {
        amount: 6.99,
        currency: '$'
      }
    },
    'India': {
      monthly: {
        amount: 999,
        currency: '₹'
      },
      annual: {
        amount: 499,
        currency: '₹'
      }
    }
  }

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let country = 'Other'; // Default

    // Check specific cases for the US or India
     if (timeZone === 'Asia/Kolkata' || timeZone === 'Asia/Calcutta') {
       setCountry("India")
    }

    console.log('User Time Zone:', timeZone);
    console.log('Country:', country);

    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);


  useEffect(() => {
    if (verifyOrderData){
      console.log("verifyOrderData:", verifyOrderData)
      if (verifyOrderData?.status){
        displayToast('success', verifyOrderData?.message || "Payment successful!\nRedirecting to dashboard")
        navigate(ROUTES.INBOX, { replace: true })
      } else {
        displayToast('error', verifyOrderErr?.message || "Payment Failed!")
      }
    }

    if (verifyOrderErr) {
      displayToast('error', verifyOrderErr?.data?.message || "Something went wrong")
    }
  }, [verifyOrderData, verifyOrderErr])

  useEffect(() => {
    if (createOrderData) {
      const options = {
        key: createOrderData?.data.rzp_key,
        currency: createOrderData?.data.currency,
        amount: createOrderData?.data.amount,
        name: createOrderData?.data.name,
        description: createOrderData?.data.description,
        // image: "http://localhost:1337/logo.png",
        order_id: createOrderData?.data.rzp_order_id,
        handler: function (response: any) {
          console.log(response)
          verifyOrder(response)
        },
        prefill: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          contact: user.phone,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', function (response: any) {
        console.log(response)
        verifyOrder(response)
      });
      paymentObject.open();

    };
    if (createOrderErr) {
      displayToast('error', createOrderErr?.data?.error?.message)
      console.log(createOrderErr);
    }

  }, [createOrderData, createOrderErr])



  const handleClickLoginButton = () => {
    createOrder({ plan: selectedPlan, country: Country == "India" ? 'IN':'OTH'  })
  }

  const LoadingSpinner = () => (
    <div role="status">
      <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  )


  return (
    <div style={styles.CONTAINER} className="min-h-screen bg-gradient-to-b from-white to-blue-50 flex items-center justify-center p-6">
      <div className="max-w-lg w-full  rounded-lg p-6  pt-0">
        {/* Header Section */}
        <div className="flex items-center justify-center m">
          <img src={LPLogoWName} className="w-[200px]" alt="Logo" />
        </div>
        {/* <h2 className="text-2xl font-bold text-center mb-6 text-gray-900">
          Choose a plan
        </h2> */}
        <ul className="text-lg text-gray-700 my-6 space-y-2">
          <li><CheckIcon className='text-green-600 text-bold size-6 inline' /> Unlimited Contacts</li>
          <li><CheckIcon className='text-green-600 text-bold size-6 inline' /> Unlimited Free Automations Triggers</li>
          <li><CheckIcon className='text-green-600 text-bold size-6 inline' /> Unified Inbox</li>
          <li><CheckIcon className='text-green-600 text-bold size-6 inline' /> Reply & Manage Comments</li>
          {/* <li><CheckIcon className='text-green-600 text-bold size-6 inline' /> Email List Builder</li> */}
        </ul>

        <div className="flex flex-col gap-4 mb-6">
          {/* Annual Plan */}
          <div
            className={`border-[3px] rounded-lg p-4 flex items-center justify-between cursor-pointer transition h-[100px] ${selectedPlan === 2
              ? "border-[#6355ff] "
              : "border-gray-300 opacity-50"
              }`}
            onClick={() => setSelectedPlan(2)}
          >
            <div className="flex items-center space-x-3">
              {/* Custom Radio Button */}
              <div
                className={`w-5 h-5 rounded-full border-2 flex align-center items-center justify-center ${selectedPlan === 2
                  ? "border-[#6355ff]"
                    : "border-gray-300"
                  }`}
              >
                {selectedPlan === 2 && (
                  <div className="w-3 h-3 rounded-full bg-[#6355ff]"></div>
                )}
                
              </div>
              <span className="text-lg font-semibold">Annual</span>

              <span className="bg-[#dfdcff] text-[#6355ff] text-xs font-medium px-2 py-1 rounded">
                Save ~{Math.round(((1-(Pricing[Country].annual.amount) / Pricing[Country].monthly.amount))*100)}%
              </span>
            </div>
            <div className="text-right">
              <p className="text-gray-500 line-through">{Pricing[Country].annual.currency}{Pricing[Country].monthly.amount}/month</p>
              <p className="text-2xl font-bold">{Pricing[Country].annual.currency}{Pricing[Country].annual.amount}/mo</p>
            </div>
          </div>

          {/* Monthly Plan */}
          <div
            className={`border-[3px] rounded-lg p-4 flex items-center justify-between cursor-pointer  h-[100px] transition ${selectedPlan === 1
              ? "border-[#6355ff]"
                : "border-gray-300 opacity-50"
              }`}
            onClick={() => setSelectedPlan(1)}
          >
            <div className="flex items-center space-x-3">
              {/* Custom Radio Button */}
              <div
                className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${selectedPlan === 1
                  ? "border-[#6355ff]"
                    : "border-gray-300"
                  }`}
              >
                {selectedPlan === 1 && (
                  <div className="w-3 h-3 rounded-full bg-[#6355ff]"></div>
                )}
              </div>
              <span className="text-lg font-semibold">Monthly</span>
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold">{Pricing[Country].monthly.currency}{Pricing[Country].monthly.amount}/mo</p>
            </div>
          </div>
        </div>


        {/* Terms and Conditions */}

        <button disabled={createOrderLoading || verifyOrderLoading} onClick={handleClickLoginButton} className={`${createOrderLoading || verifyOrderLoading ? 'opacity-50' : ''} bg-[#6355ff] flex items-center justify-center gap-4 px-4 py-2 rounded-[0.5rem] w-[100%] my-6 disabled:opacity-[.5] text-white h-[50px] text-lg`}>
          {createOrderLoading || verifyOrderLoading ? LoadingSpinner() : ''}
          {createOrderLoading || verifyOrderLoading ? verifyOrderLoading ? 'Verifying Payment' : 'Loading' : 'Continue'}
        </button>
        
        <button onClick={()=>navigate(ROUTES.AUTOMATIONS)} className='text-center w-[100%] hover:font-medium text-gray-500 text-lg'>Start with Free Plan</button>

        <div className="mt-3 flex justify-center flex-col items-center text-[14px]">
          <p className="text-[#9F9F9F]">By signing up, you agree to LinkPlease's</p>
          <p className="text-[#6355ff]"><a href="https://linkplease.co/terms-and-conditions/" target='_black'>Terms of Service</a> <span className="text-[#9F9F9F]">and</span> <a href="https://linkplease.co/privacy-policy/" target='_black'>Privacy Policy</a></p>
        </div>

        
        {/* Footer */}
        <div className="text-center mt-6 text-sm text-gray-500">
          <div className="space-x-4">
            {/* <span className="cursor-pointer hover:underline">Terms Of Service</span>
            <span className="cursor-pointer hover:underline">Privacy Policy</span> */}
            <span className="cursor-pointer hover:underline">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default PricingPage;

export default PaymentOptions
